import './poll.scss';

import PollWebtool from "@mod-publisher/js/webtools/poll";

import * as dompack from 'dompack';
import $ from 'jquery';

dompack.register('.wh-poll', node => new PollWebtool(node));

let allPolls = [];

let lastVoteCounters = [];
let interval;

dompack.onDomReady(() => {

  $(".wh-poll__poll__results").each(function(i, poll){
    allPolls.push({
      pollElement: poll,
      optionElements: $(poll).find(".wh-poll__option"),
      votesElement: $(poll).find(".wh-poll__votecount__amount")[0],
      lastVote: $($(poll).find(".wh-poll__votecount__amount")[0])[0].textContent
    });
  });


  if(allPolls.length > 0)
  {
    appendBars();
    interval = setInterval(checkIfVoteChanged, 300);
  }

});

function editBars(allPollOptions)
{
  let highest;
  let lastVote = -1;
  let tiedCount = 0;

  $.each(allPollOptions, function(i, option){
    let votes = $($(option).find(".wh-poll__option__votes")[0])[0].getAttribute("data-votes");
    let percentage = $($(option).find(".wh-poll__option__votes")[0])[0].getAttribute("data-percentage");

    let borderstyling = (300 / 100 * percentage) + "px solid #8CC8D5";
    $($(option).find(".percbar")).css("border-left", borderstyling);

    if(!$($(option).find(".wh-poll__optionlabel")[0]).hasClass("perc-added"))
    {
      $($(option).find(".wh-poll__optionlabel")[0]).append("<span class='perc'></span>");
      $($(option).find(".wh-poll__optionlabel")[0]).addClass("perc-added");
    }

    $($(option).find(".wh-poll__optionlabel .perc")[0]).text("   (" + percentage + "%)");

    if(i === 0 || lastVote === votes)
        tiedCount++;

    if(votes > lastVote)
    {
      highest = option;
      lastVote = votes;
    }
  });

  if(tiedCount !== allPollOptions.length)
    $(highest).addClass("poll-winner");
}

function checkIfVoteChanged()
{
  $.each(allPolls, function(i, poll){
    let voteCount = $(poll.votesElement)[0].textContent;
    if( poll.lastVote !== voteCount)
    {
      poll.lastVote = voteCount;
      editBars(poll.optionElements);
    }

  });


}



function appendBars()
{
  let barHTML = "<div class='percbar'></div>";

  $.each(allPolls, function(i, poll){
    $.each(poll.optionElements, function(i, option){
      $(option).append(barHTML);
    })
  });
}
