import './activities.scss';

// WebHare
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import rpc from '../../shared/js/services.rpc.json';
import { RPCFormBase, registerHandler } from '@mod-publisher/js/forms';

window.groupactivities = whintegration.config.obj.groupactivities;

// vendor
import $ from 'jquery';
window.$ = $;
import Masonry from 'masonry-layout';
require('../../shared/js/masonry-plus.js');

let activitiesGrid;
let numPersons = 1;
let invoiceFormShown = false;

class InvoiceForm extends RPCFormBase {
  constructor(node) {
    super(node);

    if (dompack.debugflags['debug'])
      this.invokeRPC('debugprefill');
  }
  getFormExtraSubmitData() {
    return { numPersons };
  }
}

dompack.onDomReady(() => {
  if (document.documentElement.classList.contains('page-activities'))
    initActivitiesPage();

  if (document.documentElement.classList.contains('page-activity'))
    initActivityPage();
});

function initActivitiesPage() {
  $('.js-collapse-filter').on('click', function(e) {
    e.preventDefault();
    $('html').toggleClass('filters-open');
  });

  $('#filtersoverlay').click(() => {
    $('html').removeClass('filters-open');
  });

  let masonrySettings = {
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    transitionDuration: 500,
    gutter: 18,
    horizontalOrder: true,
  };

  activitiesGrid = new Masonry('#activities-grid', masonrySettings);

  let url = new URL(location.href);
  let paramTypes = url.searchParams.get('t');
  let paramActivities = url.searchParams.get('a');

  if (paramTypes || paramActivities) {
    let filter = { typeids: [],
                   activityids: [],
                 };

    if (paramTypes) {
      for (const typeId of paramTypes.split(',')) {
        let id = parseInt(typeId);
        if (id) {
          $(`input[name="type"][value="${id}"]`).prop('checked', true);
          filter.typeids.push(id);
        }
      }
    }

    if (paramActivities) {
      for (const activityId of paramActivities.split(',')) {
        let id = parseInt(activityId);
        if (id) {
          $(`input[name="activity"][value="${id}"]`).prop('checked', true);
          filter.activityids.push(id);
        }
      }
    }

    applyFilterToGrid(filter);
  }

  setTimeout(() => {
    $('#activities-grid').addClass('show');
  }, 250);

  $('input[name="type"]').on('change', onTypeChange);
  $('input[name="activity"]').on('change', onActivityChange);
}

function onTypeChange() {
  onFilterChange();
}

function onActivityChange() {
  onFilterChange();
}

function onFilterChange() {
  let filter = getFilter();
  updateURLByFilter(filter);
  applyFilterToGrid(filter);
}

function getFilter() {
  let typeFilters = [];
  $('input[name="type"]').each(function() {
    if ($(this).is(':checked'))
      typeFilters.push(parseInt($(this).val()));
  });

  let activityFilters = [];
  $('input[name="activity"]').each(function() {
    if ($(this).is(':checked'))
      activityFilters.push(parseInt($(this).val()));
  });

  return {
    typeids: typeFilters,
    activityids: activityFilters,
  };
}

function updateURLByFilter(filter) {
  let url = new URL(location.href);

  if (filter.typeids.length)
    url.searchParams.set('t', filter.typeids.join());
  else
    url.searchParams.delete('t');

  if (filter.activityids.length)
    url.searchParams.set('a', filter.activityids.join());
  else
    url.searchParams.delete('a');

  window.history.pushState({}, '', decodeURIComponent(url.toString()));
}

async function applyFilterToGrid(filter) {
  let activities = await rpc.FilterActivities(whintegration.config.obj.groupactivities, filter);
  activitiesGrid.layout(activities, filter.typeids.length > 0 || filter.activityids.length > 0);
}

function initActivityPage() {
  updatePrice(1);

  let $triggerInvoiceButton = $('.js-trigger-invoice');
  let $numPersons = $('.p-activity__numpersons');
  let $nrPersonsInput = $('#activity-numpersons');

  $nrPersonsInput.get(0).value = '';

  // allow only numbers
  $nrPersonsInput.on('keypress', function(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;
    if (keyCode < 48 || keyCode > 57) {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey)//codes for backspace, delete, enter
        ev.preventDefault();
    }
  });

  $('#activity-numpersons').on('input', function() {
    numPersons = parseInt($(this).val());

    if (numPersons > 100) {
      numPersons = 100;
      this.value = 100;
    }

    updatePrice();
    $numPersons.text(numPersons ? numPersons : '');

    $triggerInvoiceButton.attr('disabled', this.checkValidity() === true && !invoiceFormShown ? null : 'disabled');
  }).trigger('input');

  $triggerInvoiceButton.click(function(evt) {
    evt.preventDefault();
    if ($triggerInvoiceButton.attr('disabled')) {
      return;
    }

    $('#invoice-form-container').show();
    invoiceFormShown = true;
    $(this).attr('disabled', 'disabled').addClass('clicked');
  })

  if (dompack.debugflags['debug'])
    $triggerInvoiceButton.trigger('click');
}

function updatePrice() {
  let price = parseFloat($('#pricepp').data('price'));
  if (!numPersons || numPersons < 1) {
    $('.js-total-price').text('---');
  } else {
    $('.js-total-price').text(((price * numPersons).toFixed(2)).replace('.', ','));
  }
}

registerHandler('redata:invoice', node => new InvoiceForm(node));
