// Auto-generated RPC interface from /opt/wh/whtree/modules/publisher/js/webtools/internal/poll.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("publisher:poll");
exports.rpcResolve = function(promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function() { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/wh/whtree/modules/publisher/lib/webtools/internal/pollrpc.whlib'

exports.getResultsForPoll = exports.GetResultsForPoll = /*RECORD*/function(/*STRING*/ toolid)
{
return request.invoke.apply(request,["GetResultsForPoll"].concat(Array.prototype.slice.call(arguments)));
}

exports.getResultsForPolls = exports.GetResultsForPolls = /*RECORD ARRAY*/function(/*STRING ARRAY*/ toolids)
{
return request.invoke.apply(request,["GetResultsForPolls"].concat(Array.prototype.slice.call(arguments)));
}

exports.castVote = exports.CastVote = /*RECORD*/function(/*STRING*/ toolid, /*STRING ARRAY*/ optionguids)
{
return request.invoke.apply(request,["CastVote"].concat(Array.prototype.slice.call(arguments)));
}
