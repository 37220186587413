import './redata.scss';

import '@mod-mso_pixelarity/webdesigns/sion/sion';

import * as dompack from 'dompack';

import './pages/activities/activities';

import './widgets/widgets';

// import './redata.scss';
// // import '@mod-redata/nodejs/redata';
// // import '@mod-redata/vendor/font-awesome-4.7.0/font-awesome.min.css';
// import './pages/activities/activities';
// // import './widgets/widgets';

// require('./web/js/jquery.dropotron.min.js');
// require('./web/js/jquery.scrollex.min.js');
// window.skel = require('./web/js/skel.min.js');
// require('./web/js/util.js');
// import './web/js/main';
